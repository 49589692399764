.customizing-main {
  width: 100vw;
  background: url("../../assets/images/newbackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: relative;
  .customizing-main-top{
    margin-top: 85px;
  }
  .customizing-main-center {
    .customizing-main-center-text-one {
      width: 354px;
      height: 43px;
      color: RGB(255, 255, 255);
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      text-shadow: 3px 1px #bbae81;
      letter-spacing: 3px;
    }
    .customizing-main-center-text-two {
      color: #253545;
      font-size: 20px;
      text-align: center;
    }

    .customizing-pick-button {
      background-color: #ad9863;
      border-radius: 5px;
      height: 52px;
      width: 352px;
      color: #ffffff;
      font-size: 22px;
      font-weight: 500;
      border: none;
    }

    .customizing-main-center-text-three {
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
    }
    .customizing-take-button {
      border: none;
      background-color: #ad9863;
      border-radius: 5px;
      height: 52px;
      width: 352px;
      color: #ffffff;
      font-size: 22px;
      font-weight: 500;
    }
    input [type="file"] {
      display: none;
    }
  }

  .customizing-main-down {
    bottom: 0;
    .customizing-main-down-text-one {
      color: #333333;
      line-height: 1.6;
      font-size: 15px;
    }
    .customizing-main-down-text-two {
      color: #333333;
      line-height: 1.6;
      font-size: 15px;
    }
  }
  .file-picker {
  }
  @media only screen and (max-width: 600px) {
    .customizing-main-top img {
      width: 200px;
      height: 72px;
    }
    .customizing-main-top{
      margin-top: 1.5rem;
    }
  }

  .webcam-container {
    .webcam-img {
      text-align: center;
    }
  }
}

.preview-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  background: url("../../assets/images/newbackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: relative;

  .preview-main-center {
    .preview-image img {
      // width: 25%;
      // height: 80%;
    }
    .preview-main-center-text-one {
      width: 354px;
      height: 43px;
      color: RGB(255, 255, 255);
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      text-shadow: 3px 1px #bbae81;
      letter-spacing: 3px;
    }
    .preview-main-center-text-two {
      color: #253545;
      font-size: 20px;
      text-align: center;
    }
    .uplod-button {
      padding: 12px 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
    .spin-loader{
      color: white;
    }
    .back-button {
      padding: 12px 60px;
      // font-size: 26px;
      // font-weight: 400;
      background-color: #ad9863;
      // color: #fff;
      // border-radius: 10px;
      // border: none;
    }
    .preview-button {
      flex-direction: row-reverse;
    }
    @media only screen and (min-width: 400px) and(max-width: 750px) {
      .preview-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .preview-main .preview-main-center .preview-image img {
        // width: 75%;
      }
      .preview-button-back {
        width: 100%;
        margin: 0 !important;
      }
      .preview-button-upload {
        width: 100%;
        margin-left: 0 !important;
      }
      .uplod-button {
        width: 90%;
      }
      .back-button {
        width: 90%;
      }
    }
    .preview-main-center-text-three {
      color: #545454;
      font-size: 18px;
    }

  }

  .preview-main-down {
    bottom: 0;
    .preview-main-down-text-one {
      color: #333333;
      line-height: 1.6;
      font-size: 15px;
    }
    .preview-main-down-text-two {
      color: #333333;
      line-height: 1.6;
      font-size: 15px;
    }
  }
}
@media only screen and (min-width: 320px) and(max-width: 750px) {
  .preview-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // width: 750px;
    // width: 0px !important;
    // width: fit-content;
    background: url("../../assets/images/newbackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
  }
  .preview-main .preview-main-center .preview-image img {
    // width: 75%;
  }
  .preview-header img {
    width: 70%
  }
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .preview-main {
    // width: fit-content;
  }
  .preview-main .preview-main-center .preview-image img {
    // width: 84%;
  }
}


@media only screen and (min-width: 360px) and(max-width: 400px) {
  .preview-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: url("../../assets/images/newbackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
  }
  .preview-button{
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }


  .preview-main .preview-main-center .preview-image img {
    // width: 75%;
  }
  .ml-4{
    margin-left: 0!important;
  }
  .preview-main-top img {
    width: 200px;
    height: 72px;
  }
  .preview-main-top{
    margin-top: 2.5rem;
  }
}
