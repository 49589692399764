.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 21px;
  // background-color: #FFFFFF;
  // box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
  //box-shadow: 0 8px 16px 0 #000000;
  position: absolute;
  bottom: 0;

  .footer-div {
    vertical-align: middle;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
  }
}
