.ant-modal-content {
  height: 400px;
  // top: 335px;
  // left: 350px;
  width: 382px;
  border-radius: 10px;
}
.ant-modal {
  width: 1100px !important;
  top: 73px;
}
.ant-modal-body {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.ant-modal-close {
  display: none;
}
.ant-modal-footer {
  display: none;
}
.preview-logo {
  position: absolute;
  top: -45px;
  left: 150px;
}
.preview-model-main {
  .preview-model-title {
    color: #1f366c;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    padding: 45px;
  }
  .preview-model-details {
    width: 321px;
    height: 84px;
    color: #333333;
    line-height: 1.4;
    letter-spacing: -0.5px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: 28px;
  }
  .preview-model-botton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }
}
@media only screen and (min-width: 390px) and(max-width: 750px) {
  .ant-modal-content {
    height: 400px;
    top: 200px;
    left: 0;
  }
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .ant-modal-content {
    height: 387px;
    top: 311px;
    width: 382px;
  }
}
// .ready-model-top-text {
//   color: #003366;
//   font-size: 44px;
//   width: 373px;
//   line-height: 1.208;
// }
// .ready-model-top-text-two {
//   color: #003366;
//   font-size: 44px;
//   text-align: center;
//   line-height: 1.208;
// }
// .ready-model-down-left {
//   width: 400px;
//   margin-left: 25px;
// }
// .ready-model-down-left-top {
//   width: 370px;
//   height: 340px;
//   background-color: lightgray;
//   margin-left: 25px;
// }
// .ready-model-down-button {
//   width: 50px;
//   height: 50px;
//   margin: 20px;
//   background-color: #ad9863;
// }
// .ready-model-message-box {
//   padding: 14px 25px 13px 22px;
//   color: #003366;
//   font-size: 22px;
//   border-radius: 5px;
//   border: 5px dotted #003366;
//   width: 206px;
//   height: 50px;
// }
// .ready-model-message-down {
//   padding: 14px 25px 13px 22px;
//   color: #fc4850;
//   font-size: 22px;
//   border-radius: 5px;
//   border: 5px dotted #fc4850;
//   width: 206px;
//   height: 50px;
//   position: absolute;
//   bottom: 40px;
// }
